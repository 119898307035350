<template>
  <div class="home">
    <div class="login">
      <div class="title">MIAS-3000 眼科OCT影像辅助诊断软件</div>
      <div class="login_wrap">
        <div class="input-box" :class="focus === 1 ? 'focus' : ''">
          <input
            autocomplete="new-password"
            onKeypress="javascript:if(event.keyCode == 32)event.returnValue = false"
            type="text"
            v-model="account"
            class="linear-input-instance"
            placeholder="请输入用户名"
            @focus="login_tel_focus"
            @blur="login_tel_blur"
          />
        </div>
        <div class="login_tel_mark">{{ login_tel_mark }}</div>
        <div class="input-box" :class="focus === 2 ? 'focus' : ''">
          <input
            autocomplete="new-password"
            onKeypress="javascript:if(event.keyCode == 32)event.returnValue = false"
            @keyup.enter="login"
            type="password"
            v-model="password"
            class="linear-input-instance"
            placeholder="请输入密码"
            @focus="login_pwd_focus"
            @blur="login_pwd_blur"
          />
        </div>
        <div class="login_pwd_mark">{{ login_pwd_mark }}</div>
        <el-button
          v-loading="loading"
          :disabled="disabled"
          class="start"
          type="primary"
          @click="login"
          >立即登录</el-button
        >
        <div class="tips">
          <b>注意：</b
          >使用本软件之前，请您一定详细阅读本产品说明书，并按照要求操作使用
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Service from '@/api/service'
import rest from '@/utils/rest'
import VueCookie from 'vue-cookie'
export default {
  name: 'Login',
  data() {
    return {
      login_tel_mark: '',
      login_pwd_mark: '',
      reg: {
        login_tel: false,
        login_pwd: false
      },
      focus: 0,
      password: '',
      account: '',
      loading: false,
      focusInp: ''
    }
  },
  computed: {
    disabled() {
      if (this.account && this.password) return false
      return true
    }
  },
  methods: {
    login_tel_focus() {
      this.focusInp = 'account'
    },
    login_pwd_focus() {
      this.focusInp = 'password'
    },
    login_tel_blur() {
      this.reg_login_tel()
      this.focusInp = ''
    },
    login_pwd_blur() {
      this.reg_login_pwd()
      this.focusInp = ''
    },
    async login() {
      this.reg_login_tel()
      this.reg_login_pwd()
      if (this.reg.login_tel && this.reg.login_pwd) {
        this.loading = true
        setTimeout(() => {
          this.loading = false
        }, 2000)
        let data = {
          username: this.account,
          password: this.password
        }
        let re = await Service.accountLogin(data)
        rest.setToken(re.token)
        VueCookie.set('accountId', re.accountId)
        VueCookie.set('username', this.account)
        this.loading = false
        this.$message.success('登录成功！')
        setTimeout(() => {
          if (re.roleType === 'SUPERADMIN') {
            VueCookie.set('roleType', 'SUPERADMIN')
            this.$router.push('/')
          } else {
            VueCookie.set('roleType', 'INSTITUTION')
            this.$router.push('/fileList')
          }
        }, 800)
      }
    },
    reg_login_tel() {
      this.account = this.removeAllSpace(this.account)
      const reg = /^[0-9a-zA-Z]{2,12}$/
      if (!this.account) {
        this.login_tel_mark = '请输入您的账号名!'
        this.reg.login_tel = false
      } else {
        if (this.account !== '') {
          if (!reg.test(this.account)) {
            this.login_tel_mark = '用户名只能包含0-9、A-Z、a-z，长度为2-12'
            this.reg.login_tel = false
          } else {
            this.reg.login_tel = true
            this.login_tel_mark = ''
          }
        }
      }
    },
    reg_login_pwd() {
      this.password = this.removeAllSpace(this.password)
      // eslint-disable-next-line no-control-regex
      const reg = /[^(\x00-\x7f)]/
      if (!this.password) {
        this.login_pwd_mark = '请输入您的密码!'
        this.reg.login_pwd = false
      } else {
        if (this.password !== '') {
          if (reg.test(this.password)) {
            this.login_pwd_mark = '密码格式不正确，重新输入!'
            this.reg.login_pwd = false
          } else {
            this.reg.login_pwd = true
            this.login_pwd_mark = ''
          }
        }
      }
    },
    removeAllSpace(str) {
      // 清除所有空格
      return str.replace(/\s+/g, '')
    }
  }
}
</script>
<style lang="scss" scoped>
.home {
  width: 100vw;
  height: 100vh;
  background-image: url('../assets/images/Login_bg.png');
  background-size: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login {
  width: 432px;
  height: 500px;
  background-color: #fff;
  //   opacity: 0.6;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 12px 20px rgba(38, 38, 38, 0.12);
  border-radius: 12px;
}
.title {
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  margin-top: 40px;
}
.login_wrap {
  padding: 0 36px;
  height: 300px;
  margin-top: 100px;
}
.input-box {
  height: 46px;
  width: 100%;
  margin-top: 40px;
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  align-items: center;
}
.linear-input-instance {
  flex: 1;
  padding: 10px 0;
  line-height: 24px;
  border: none;
  min-width: 0;
  font-size: 18px;
  outline: none;
}
.focus {
  border-bottom: 1px solid #0598fa;
}
.start {
  width: 100%;
  margin-top: 54px;
}
.version {
  width: 100%;
  text-align: center;
  margin-top: 85px;
  color: #666;
  font-size: 13px;
}
.about {
  color: #0598fa;
}
.tips {
  width: 100%;
  text-align: center;
  margin-top: 5px;
  color: #666;
  font-size: 9px;
}
.tip1 {
  margin-top: 30px;
}
.login_yzm_mark,
.login_tel_mark,
.login_pwd_mark {
  width: 100%;
  min-height: 10px;
  font-size: 12px;
  color: #f51400;
  line-height: 17px;
  padding-top: 3px;
}
</style>
